@font-face {
  font-family: 'Oracle Sans Regular';
  src: local('Oracle Sans'), url(./fonts/OracleSans_Rg.ttf) format('truetype');
}

@font-face {
  font-family: 'Oracle Sans Light';
  src: local('Oracle Sans'), url(./fonts/OracleSans_Lt.ttf) format('truetype');
}

@font-face {
  font-family: 'Oracle Sans Bold';
  src: local('Oracle Sans'), url(./fonts/OracleSans_Bd.ttf) format('truetype');
}

  .ui.container {
    margin-left: 0em!important;
    margin-right: 0em!important;
    width:auto!important;
  }

@media only screen and (min-width: 767px) {
  .ui.container {
    margin-left: 0em!important;
    margin-right: 0em!important;
  }
}

 /* LINKTREE */



 .linktree {
  justify-content:center; 
  align-items: center; 
  align-self: center; 
  display: flex; 
  flex-direction: column;
  margin: auto; 
  width: 370;
  text-align: center;
  background: #7a0f1e;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  letter-spacing: 2px;
  
 }

.links {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid white;
  border-width: 2px;
  width: 290px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  cursor: pointer;
}

.links-trabalhe {
  text-align: center;
  margin-top: 100px;
  padding: 10px;
  border: 1px solid white;
  border-width: 1px;
  width: 220px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  cursor: pointer;
  
}

.links-evento {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid white;
  border-width: 2px;
  width: 290px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  cursor: pointer;
  background-color: green;
}

a {
text-decoration: none;
color: white;
transition: color 1s; 
}

.bottom-text {
text-align: center;
margin-bottom:10px;
font-size: 20px;
font-weight: bold;
}

a:hover {
color: #000;
background: #fff;
}

.profile-name {
  text-align: center;
  padding-top: 30px;
}

.profile-info {
  text-align: left;
  padding: 10px;
  width: 350px;
}

 /* LINKTREE */

body {
  background-color:#fff !important;
  background: #fff !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.myClip {
  overflow : hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.myClip2 {
  overflow : hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.panel-list {
  height: 200px;
  pointer-events: none;
}

.panel-list .dx-list-item {
  color: #fff;
  text-align: center;
  border-top: 1px solid rgba(221, 221, 221, .2);
}

.dx-drawer-shrink #content {
  overflow: hidden;
  transition: all 0.4s ease-out;
  column-width: 900px;
  
}
.dx-drawer-shrink.dx-drawer-opened #content{
  column-width: 300px;
  margin-right: -10px;
}

#content {
  height: 100%;
  padding: 10px 20px;
}

#content h2 {
  font-size: 26px;
}

.left {
  float: left;
  padding: 20px;
  background: rgba(191, 191, 191, 0.15);
  
}

.left .list .dx-list-group-header {
  color: #f05b41;
  font-weight: normal;
  font-size: 18px;
  
}

.dx-list-group-header {
  background-color: #7a0d1e !important;
  color:#fff;
}



.left .list .hotel {
  float: left;
}

.left .list .hotel .name {
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ball {
  width: 140px;
  height: 140px;
  font-size: 15px;
  border-radius: 16px;
}

.ball-mobile {
  width: 100px;
  height: 100px;
  font-size: 11px;
  border-radius: 16px;
}

.linke a:visited a:link a:hover a:active {
  color: 'white';
}

.clipBola {
  width: 250px;
  height: 250px;
  border-radius:50%;
  object-fit: cover;
}

.containerFix {
  
  width: 1024px;
  margin-left: 25;
  margin-right: 25;
  color:white;
  justify-content: center;
}

.topMost {
  display: flex;
  justify-content: center;
}

.bevel {
  display: inline-block;
    color: #fff;
    text-shadow: 0 0 2px rgba(0,0,0,.3);
    font-family: sans-serif;
    box-shadow:
        inset 0 0 2px 0 rgba(255,255,255,.4),
        inset 0 0 3px 0 rgba(0,0,0,.4),
        inset 0 0 3px 5px rgba(0,0,0,.05),
        2px 2px 4px 0 rgba(0,0,0,.25);
    border-radius: 4px;
    padding: 8px 16px;;
    font-size: 12px;
    line-height: 14px;
    position: relative;
}

.octagon {
  width: 500px;
  height: 70px;
  background: #182c3f;
  position: relative;
  box-shadow:
        inset 0 0 2px 0 rgba(255,255,255,.4),
        inset 0 0 3px 0 rgba(0,0,0,.4),
        inset 0 0 3px 5px rgba(0,0,0,.05),
        2px 2px 4px 0 rgba(0,0,0,.25);
}
.octagon:before {
  content: "";
  width: 500px;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 20px solid #182c3f;
  border-left: 20px solid #050716;
  border-right: 20px solid #050716;
  
}
.octagon:after {
  content: "";
  width: 500px;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 20px solid #182c3f;
  border-left: 20px solid #050716;
  border-right: 20px solid #050716;
}

.ui.form input {
  background :  #405453 !important;
  border: 1px solid !important;;
  border-width: 5px;
  border-color: #fff !important;
  color: #fff !important;
  font-size: 40px;
  border-radius: 25px !important;
}

.certa {
  filter: invert(30%) sepia(95%) saturate(1528%) hue-rotate(169deg) brightness(91%) contrast(103%);
}

.errada {
  filter: invert(54%) sepia(15%) saturate(607%) hue-rotate(131deg) brightness(92%) contrast(89%);
}

::-webkit-scrollbar{
  width: 5px !important;
}

.orr:before {
  content:'tste'
}

.ui.dropdown .menu>.item  {
  padding: 10px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.player-wrapper {
 
  padding-top: 58% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.StickyBox {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sidebarWrapper {
  flex: 1;
  position: relative;
}

.coverAgenda {
 /* Location of the image */
 background-image: url(/images/agenda_web.jpg);
  
 /* Background image is centered vertically and horizontally at all times */
 background-position: center center;
 
 /* Background image doesn't tile */
 background-repeat: no-repeat;
 
 /* Background image is fixed in the viewport so that it doesn't move when 
    the content's height is greater than the image's height */
 
 
 /* This is what makes the background image rescale based
    on the container's size */
 background-size: cover;
 
 /* Set a background color that will be displayed
    while the background image is loading */
 background-color: #464646;
 width:100%;
 height: 430px;
}

.coverAgendaMobile {
  /* Location of the image */
  background-image: url(/images/agenda_mobile.jpg);
    
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;

  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn't move when 
    the content's height is greater than the image's height */


  /* This is what makes the background image rescale based
    on the container's size */
  background-size: cover;

  /* Set a background color that will be displayed
    while the background image is loading */
  background-color: #464646;
  width:100%;
  height: 435px;
}

.savethedate {
  /* Location of the image */
  background-image: url(/images/save_date.jpg);
   
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  
  
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  
  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;
  width:100%;
  height: 100%;
 }

 .signInPattern {
  background-image: url(/images/oracle_pattern.jpg);
  background-repeat: repeat;
  background-size: 256px; 
  display: flex;
  justify-content: center;
 }

 .dx-button-content{
  padding: 10px !important;
  line-height: 8px !important;
  
}

.horizontal-scroll {
  --scroll-band: 10px;
  cursor: grab;
  gap: var(--scroll-band);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 10px;
}
.horizontal-scroll--dragging {
  cursor: grabbing;
  scroll-behavior: auto !important;
  user-select: none !important;
}
.horizontal-scroll--dragging > * {
  cursor: grabbing;
}
.horizontal-scroll--release {
  transition: gap 0.3s ease-in-out;
}
.horizontal-scroll--hide-scrollbars {
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.horizontal-scroll--hide-scrollbars::-webkit-scrollbar {
  background: transparent !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  -webkit-appearance: none !important;
}
.horizontal-scroll--native-scroll {
  overflow: auto;
}
